<template>
  <b-modal
    id="purchase"
    size="lg"
    hide-header
    v-model="show"
    title="Purchase Filters"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
  >
    <b-card>
      <b-alert
        variant="danger"
        dismissible
        :show="hasError"
        @dismissed="resetErrorMessage()"
        >{{ errorMessage }}</b-alert
      >

      <div class="modal-content">
        <b-card-header header-class="vendor-card">
          <h5>
            <b>{{ modalTitle() }}</b>
          </h5>
        </b-card-header>
        <b-card-body :class="{ loading: this.loading }">
          <b-list-group v-if="summaryStage" class="no-border">
            <b-list-group-item>
              <b-row>
                <b-col cols="4" class="strong">Order Type:</b-col>
                <b-col cols="8">FI Prospect List</b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row>
                <b-col cols="4" class="strong">Number of FIs:</b-col>
                <b-col cols="8">{{ totalRecords | numberFormat }}</b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row>
                <b-col cols="4" class="strong">Delivery Method:</b-col>
                <b-col cols="8">CSV / Excel</b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item v-if="tokens !== 0">
              <b-row>
                <b-col cols="4" class="strong">Token Used:</b-col>
                <b-col cols="8"
                  >{{ tokensBeingUsed }} of {{ tokens }} total</b-col
                >
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row class="pt-2 strong">
                <b-col cols="4">Total Cost:</b-col>
                <b-col cols="8">{{ amount | currencyFormat }}</b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row class="pt-3">
                <b-col cols="12" class="text-center">
                  <b-button
                    size
                    variant="secondary"
                    class="fw-btn mx-1"
                    @click="closeModal"
                    >Cancel</b-button
                  >
                  <b-button
                    size
                    variant="primary"
                    class="fw-btn mx-1"
                    @click="proceedToPayment"
                    >Proceed</b-button
                  >
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
          <b-list-group v-else-if="paymentStage">
            <b-list-group-item>
              <b-row>
                <b-col sm="8">
                  <b>
                    <i>Total Financial Institutions:</i>
                  </b>
                </b-col>
                <b-col sm="4" class="text-right">
                  <b>{{ totalRecords | numberFormat }}</b>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row>
                <b-col sm="8">
                  <b>
                    <i>Total Price:</i>
                  </b>
                </b-col>
                <b-col sm="4" class="text-right">
                  <b>{{ amount | currencyFormat }}</b>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <div v-if="this.clientToken">
                <DropIn
                  wrapperClass="p-3"
                  :amount="amount"
                  :authToken="clientToken"
                  :collectCardHolderName="true"
                  :enableDataCollector="true"
                  :enablePayPal="false"
                  @btError="handleError"
                  @closeModal="closeModal"
                  @btSuccess="handleSuccess"
                ></DropIn>
              </div>
              <div v-else>
                <content-placeholders>
                  <content-placeholders-heading :img="true" />
                  <content-placeholders-text :lines="3" />
                </content-placeholders>
              </div>
            </b-list-group-item>
          </b-list-group>
          <b-list-group v-else-if="confirmationStage" class="no-border">
            Thank you for your order. You may download your data using the link
            below. You will also receive an email with a download link.
            <b-list-group-item class="pt-5 text-center">
              <b-row class="justify-content-center">
                <b-col cols="12">
                  <b>Click here to download your file:</b>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item class="pt-3 text-center">
              <b-row>
                <b-col cols="12">
                  <b-button
                    size
                    variant="primary"
                    class="fw-btn"
                    :href="downloadUrl"
                    target="_blank"
                    >Download</b-button
                  >
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item class="pt-3 text-center">
              <b-row>
                <b-col cols="12">
                  <b-button
                    size
                    variant="secondary"
                    class="fw-btn"
                    @click="closeModal"
                    >Close</b-button
                  >
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </b-card-body>
      </div>
    </b-card>
  </b-modal>
</template>

<script>
import DropIn from '@/views/brainTree/dropIn'

export default {
  name: 'Order',
  props: [
    'show',
    'totalRecords',
    'amount',
    'stage',
    'clientToken',
    'loading',
    'downloadUrl',
    'tokens',
    'tokensBeingUsed'
  ],
  components: {
    DropIn
  },
  data() {
    return {
      errorMessage: null
    }
  },
  beforeUpdate() {},
  computed: {
    summaryStage: function() {
      return this.stage === 'summary'
    },
    paymentStage: function() {
      return this.stage === 'payment'
    },
    confirmationStage: function() {
      return this.stage === 'confirmation'
    },
    hasError: function() {
      return this.errorMessage !== null
    }
  },
  methods: {
    modalTitle: function() {
      if (this.summaryStage) {
        return 'Order Details'
      } else if (this.paymentStage) {
        return 'Order Summary'
      } else if (this.confirmationStage) {
        return 'Order Confirmation - Thank You!'
      }
    },
    closeModal: function() {
      this.$emit('closeModal')
    },
    minimumOrder: function() {
      return this.totalRecords < 500
    },
    proceedToPayment: function() {
      this.$emit('createOrder')
    },
    handleError: function(msg) {
      this.errorMessage = msg
    },
    handleSuccess: function(payload) {
      this.errorMessage = null
      this.$emit('pay', payload)
    },
    resetErrorMessage() {
      this.errorMessage = null
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-content {
  border: 1px solid #d1d4d7;
}

.no-border {
  .list-group-item {
    border: none;
  }
}

.strong {
  font-weight: bold;
}

.loading {
  opacity: 0.5;
  pointer-events: none;
}
</style>
